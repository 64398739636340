/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://shjgl2ku55hedd6mkyiw5k5hhu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qaswfgi4xvbipa63sk54xeuswa",
    "aws_cognito_identity_pool_id": "us-east-1:4177ce4d-29ef-4c6d-84d9-4c29494ff05e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_NjND8uUyq",
    "aws_user_pools_web_client_id": "78s1h62i6380resngpajd30r7f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "jtag-www-storage-01143623-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
