import { useEffect, Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import { API } from "aws-amplify";
import config from './aws-exports'
const Contact = lazy(() => import("./pages/Contact"));
const Background = lazy(() => import("./pages/Background"));
const Institutional = lazy(() => import("./pages/Institutional"));
const Home = lazy(() => import("./pages/Home"));
const EmeraldPage = lazy(() => import("./pages/EmeraldPage"));
const NewCustomerPage = lazy(() => import("./pages/withAuth/Customer/NewCustomer"));
const ListWebsiteMessages = lazy(() => import("./pages/withAuth/WebsiteMessages/ListWebsiteMessages"));


function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: 'ease',
    });
    AOS.refresh();

  }, [])

  useEffect(() => {
    API.configure(config)
  }, [])

  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<div />}>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<Home />} />
            <Route path={`${process.env.PUBLIC_URL + "/emerald"}`} element={<EmeraldPage />} />
            <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact />} />
            <Route path={`${process.env.PUBLIC_URL + "/internal/customers/new"}`} element={<NewCustomerPage />} />
            <Route path={`${process.env.PUBLIC_URL + "/internal/messages/list"}`} element={<ListWebsiteMessages />} />
            {/* <Route path={`${process.env.PUBLIC_URL + "/background"}`} element={<Background />} /> */}
            <Route path={`${process.env.PUBLIC_URL + "/institutional"}`} element={<Institutional />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </Suspense>
      </NavScrollTop>
    </Router>
  );
}

export default App;
